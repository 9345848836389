import { useMemo } from "react";
import styles from "./AssociatedMediaPointer.css";
import classes from "classnames";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { addFloatingPanel } from "../../actions/sidePanel";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { ImageStack as MediaIcon } from "@churchofjesuschrist/eden-icons";
import { PanelData } from "../BasePointer";
import IconPointer from "../IconPointer";
import analytics from "../../../util/analytics";

class AssociatedMediaPanel extends PanelData {
    constructor(args) {
        super(args);

        const { addFloatingPanel, associatedMedia = {} } = args;

        this.addFloatingPanel = addFloatingPanel;
        this.associatedMedia = associatedMedia;
    }

    handle = () => {
        this.addFloatingPanel(this);
    };
}

export const AssociatedMediaPointer = ({
    addFloatingPanel,
    children,
    className,
    disambiguate,
    icon,
    id,
    selectI18nStringById,
    syncedScrollObserver,
}) => {
    const handleClick = (e) => {
        e.preventDefault();

        const panelData = new AssociatedMediaPanel({
            type: "associatedMedia",
            id,
            addFloatingPanel,
        });

        disambiguate(panelData);

        analytics.fireAssociatedRelatedContentEvent({ context: "associated" });
    };

    if (!children && !icon) return null;

    return (
        <IconPointer
            className={classes(icon && styles.icon, className)}
            Icon={MediaIcon}
            onClick={handleClick}
            scrollId={id}
            syncedScrollObserver={syncedScrollObserver}
            title={selectI18nStringById("associatedMediaGutterIcon")}
            type="media"
        >
            {children}
        </IconPointer>
    );
};

const AssociatedMediaPointerContainer = (props) => {
    let selectI18nStringById = useSelectI18nStringById();

    const dispatch = useDispatch();
    const actions = useMemo(
        () => bindActionCreators({ addFloatingPanel }, dispatch),
        [dispatch]
    );

    return (
        <AssociatedMediaPointer
            selectI18nStringById={selectI18nStringById}
            {...actions}
            {...props}
        />
    );
};

export default AssociatedMediaPointerContainer;
