// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { forwardRef, useEffect, useRef } from "react";
import styles from "./IconPointer.css";
import classes from "classnames";

const IconPointer = forwardRef(
    (
        {
            children,
            className,
            Icon,
            onClick,
            scrollId,
            syncedScrollObserver,
            title,
            type,
        },
        ref
    ) => {
        const fallbackRef = useRef();
        ref = ref || fallbackRef;

        useEffect(() => {
            const pointerRef = ref.current;
            Icon && syncedScrollObserver?.observe(pointerRef);

            return () => Icon && syncedScrollObserver?.unobserve(pointerRef);
            // TODO: Disable below is temporary to have as little functionality change as possible in this PR
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [syncedScrollObserver, Icon]);

        if (!children && !Icon) return null;

        return (
            <span
                className={classes(
                    styles.iconPointer,
                    !children && styles.icon,
                    className
                )}
                data-pointer-type={type}
                data-scroll-id={scrollId}
                onClick={onClick}
                ref={ref}
                title={title}
            >
                {children || (
                    <button
                        aria-label={title}
                        className={styles.iconPointerButton}
                    >
                        <Icon size={"1.5rem"} className={styles.Icon} />
                    </button>
                )}
            </span>
        );
    }
);
IconPointer.displayName = "IconPointer";

export default IconPointer;
