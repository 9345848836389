import { useState } from "react";
import styles from "./InteractiveImage.css";
import classes from "classnames";
import {
    MediaModal as EdenMediaModal,
    ModalImage as EdenModalImage,
} from "@churchofjesuschrist/eden-media-modal";
import Image from "../Image/Image";
import { isPdf, srcSetFromId } from "../../../util/utils";

const PdfWrapper = ({ altSource, children }) => {
    return isPdf(altSource) ? (
        <a href={altSource} rel="noreferrer" target="_blank">
            {children}
        </a>
    ) : (
        children
    );
};

const InteractiveImage = ({
    alt,
    altSource,
    attribution,
    caption,
    className,
    onClick,
    isAssociatedMedia,
    src,
    srcSet,
    srcset = srcSet, // prefer srcset
    title,
    assetId,
    ...props
}) => {
    const [active, setActive] = useState(false);
    const isPDF = isPdf(altSource);

    const handleClick = () => {
        onClick && onClick();

        setActive(true);
    };

    return (
        <div>
            <PdfWrapper altSource={altSource}>
                <Image
                    alt={alt}
                    caption={caption}
                    className={classes(
                        className,
                        !isPDF && styles.imageWithModal
                    )}
                    isAssociatedMedia={isAssociatedMedia}
                    isPDF={isPDF}
                    src={src}
                    srcset={srcset}
                    height={props["data-height"]}
                    width={props["data-width"]}
                    onClick={handleClick}
                    {...props} // `sizes` used with `srcset` passed through props
                />
            </PdfWrapper>

            {active && !isPDF && (
                <EdenMediaModal open onClose={() => setActive(false)}>
                    <EdenModalImage
                        alt={alt}
                        attribution={attribution}
                        caption={caption || title || alt}
                        src={src}
                        srcSet={srcSetFromId({
                            id: assetId,
                            // 1600 set as the max for the modal by Jen and designers
                            max: 1600,
                        })}
                    />
                </EdenMediaModal>
            )}
        </div>
    );
};

export default InteractiveImage;
