// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PanelData } from "../BasePointer";
import connectAnnotations from "../../containers/annotations";

class HighlightPanel extends PanelData {
    constructor(args) {
        super(args);

        const { changeActiveAnnotation } = args;

        this.changeActiveAnnotation = changeActiveAnnotation;
    }

    handle = () => {
        this.changeActiveAnnotation(this.id);
    };
}

const HighlightPointer = ({
    annotationId,
    changeActiveAnnotation,
    children,
    disambiguate,
    onClick,
}) => {
    const panelData = new HighlightPanel({
        type: "highlight",
        id: annotationId,
        changeActiveAnnotation,
    });

    return (
        <span
            onClick={() => {
                disambiguate(panelData);
                onClick && onClick();
            }}
        >
            {children}
        </span>
    );
};

const HighlightPointerContainer = connectAnnotations(
    ({ changeActiveAnnotation, ownProps }) => (
        <HighlightPointer
            changeActiveAnnotation={changeActiveAnnotation}
            {...ownProps}
        />
    )
);

export default HighlightPointerContainer;
