import { Component, createRef, useMemo } from "react";
import styles from "./AnnotationPointer.css";
import classes from "classnames";
import debounce from "debounce";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
    ImageStack as MultiMediaIcon,
    Note as NoteIcon,
    Notes as MultiNoteIcon,
    Tag as TagIcon,
    Notebook as NotebookIcon,
    Link as LinkIcon,
} from "@churchofjesuschrist/eden-icons";
import { addFloatingPanel } from "../../actions/sidePanel";
import { selectHighlightAnnotationById } from "../../selectors";
import { useSelectI18nStringById } from "../../../util/custom-hooks";
import { PanelData } from "../BasePointer";
import IconPointer from "../IconPointer";

const handleIntersectionObserver = (entries) =>
    entries.forEach((entry) => entry.target.handleMultiNote());

export class AnnotationPanel extends PanelData {
    constructor(args) {
        super(args);

        const {
            addFloatingPanel,
            annotationId,
            autoFocus,
            closePanel = (f) => f,
        } = args;

        this.addFloatingPanel = addFloatingPanel;
        this.closePanel = closePanel;

        // EditorPanel data
        this.annotationId = annotationId;
        this.autoFocus = autoFocus;
    }

    handle = () => this.addFloatingPanel(this);
}

const iconLookup = {
    note: NoteIcon,
    link: LinkIcon,
    tag: TagIcon,
    notebook: NotebookIcon,
    multiNote: MultiNoteIcon,
    multiMedia: MultiMediaIcon,
};

class AnnotationPointer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            multiIcon: false,
        };

        this.self = createRef();
    }

    componentDidMount() {
        AnnotationPointer.intersectionObserver =
            AnnotationPointer.intersectionObserver ||
            new IntersectionObserver(handleIntersectionObserver, {
                threshold: 0.1,
            });

        if (this.props.icon) {
            AnnotationPointer.intersectionObserver.observe(this.self.current);

            this.self.current.handleMultiNote = this.handleMultiNote;
        }

        window.addEventListener("resize", this.debouncedHandleMultiNote);
        window.addEventListener(
            "determine-multi-note",
            this.debouncedHandleMultiNote
        );
    }

    componentWillUnmount() {
        if (this.props.icon) {
            AnnotationPointer.intersectionObserver.unobserve(this.self.current);

            this.state.multiIcon &&
                window.dispatchEvent(new Event("determine-multi-note"));
        }

        window.removeEventListener("resize", this.debouncedHandleMultiNote);
        window.removeEventListener(
            "determine-multi-note",
            this.debouncedHandleMultiNote
        );
    }

    handleClick = (e) => {
        const {
            children,
            addFloatingPanel,
            annotation,
            closePanel,
            disambiguate,
        } = this.props;
        e.preventDefault();
        e.stopPropagation();

        if (!children && e.isTrusted) {
            document
                .elementsFromPoint(e.clientX, e.clientY)
                .filter((node) => node.dataset.pointerType)
                .forEach((node) => node.click());
        } else {
            const panelData = new AnnotationPanel({
                type: "annotation",
                id: annotation.id,
                addFloatingPanel,
                closePanel,
            });

            disambiguate(panelData);
        }
    };

    handleMultiNote = () => {
        if (this.self.current) {
            const ICON_SIZE = 24;
            const { left, top } = this.self.current.getBoundingClientRect();
            const x = left + ICON_SIZE / 2;
            const y = top + ICON_SIZE / 2;

            const pointerNodes = document
                .elementsFromPoint(x, y)
                .filter((node) => node.dataset.pointerType);
            const containsMedia = pointerNodes.find(
                (node) => node.dataset.pointerType === "media"
            );
            const multiIcon = containsMedia ? "multiMedia" : "multiNote";

            if (
                pointerNodes.length > 1 &&
                pointerNodes[0] === this.self.current
            ) {
                this.setState({ multiIcon });
            }
        }
    };

    debouncedHandleMultiNote = debounce(this.handleMultiNote, 200);

    render() {
        const {
            annotation,
            children,
            className,
            syncedScrollObserver,
            selectI18nStringById,
        } = this.props;
        const AnnotationIcon =
            annotation && iconLookup[this.state.multiIcon || annotation.icon];

        if (!children && AnnotationIcon === undefined) return null;

        return (
            <IconPointer
                className={classes(styles.annotationPointer, className)}
                Icon={AnnotationIcon}
                onClick={this.handleClick}
                ref={this.self}
                scrollId={annotation?.id}
                syncedScrollObserver={syncedScrollObserver}
                title={selectI18nStringById("annotationNoteTitle")}
                type="annotation"
            >
                {children}
            </IconPointer>
        );
    }
}
const AnnotationPointerContainer = (props) => {
    const dispatch = useDispatch();
    const annotation = useSelector((state) =>
        selectHighlightAnnotationById(state, props.annotationId)
    );
    const selectI18nStringById = useSelectI18nStringById();
    const actions = useMemo(
        () => bindActionCreators({ addFloatingPanel }, dispatch),
        [dispatch]
    );

    return (
        <AnnotationPointer
            annotation={annotation}
            selectI18nStringById={selectI18nStringById}
            {...actions}
            {...props}
        />
    );
};

AnnotationPointerContainer.displayName = "AnnotationPointerContainer";

export default AnnotationPointerContainer;
