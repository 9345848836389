export class PanelData {
    constructor({
        addFloatingPanel,
        id = "",
        type = "disambiguation",
        dataPromises = [],
        handle,
        panels = [],
    }) {
        this.id = id;
        this.type = type;
        this.addFloatingPanel = addFloatingPanel;
        this.dataPromises = dataPromises;
        this.panels = panels;

        if (handle) {
            this.handle = handle;
        }
    }

    getData = () => Promise.resolve();
    handle = () =>
        Promise.all(this.dataPromises).finally(() => {
            this.addFloatingPanel({
                id: this.id,
                panels: this.panels,
                type: "disambiguation",
            });
        });
}
