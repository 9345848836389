import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
    addBookmark,
    addFolder,
    addHighlight,
    addTag,
    changeActiveAnnotation,
    createFolder,
    deleteAnnotation,
    getBookmarks,
    getFolders,
    getHighlights,
    getSets,
    getTags,
    removeFolder,
    removeTag,
    reorderBookmarks,
    updateAnnotation,
    updateAnnotationSet,
    updateBookmarkLocation,
    updateBookmarkName,
    updateHighlightOffsets,
    updateHighlightStyle,
    updateNote,
} from "../actions/annotations";
import {
    selectActiveAnnotationId,
    selectActiveBookmark,
    selectActiveHighlightAnnotation,
    selectAnnotationsInitialized,
    selectAnnotationsOutOfService,
    selectBookmarkById,
    selectFolders,
    selectHighlightAnnotationById,
    selectBookmarksByPid,
    selectHighlightsByDocId,
    selectHighlightAnnotationsByDocId,
    selectLoggedIn,
    selectSortedBookmarks,
    selectTags,
} from "../selectors";
import { toContentUri } from "../../util/uri-utils";

const mapStateToProps = (state, ownProps) => {
    let location = ownProps.location;

    return {
        activeAnnotationId: selectActiveAnnotationId(state),
        activeBookmark: selectActiveBookmark(state),
        activeHighlightAnnotation: selectActiveHighlightAnnotation(state),
        annotationsInitialized: selectAnnotationsInitialized(state, location),
        annotationsOutOfService: selectAnnotationsOutOfService(state),
        bookmarks: selectSortedBookmarks(state),
        coreContentUri: toContentUri(location.pathname),
        defaultStyle: state.localSettings.defaultStyle,
        folders: selectFolders(state),
        highlights: selectHighlightsByDocId(state, location),
        pageHighlightAnnotations: selectHighlightAnnotationsByDocId(
            state,
            location
        ),
        loggedIn: selectLoggedIn(state),
        selectBookmarkById: selectBookmarkById(state),
        selectBookmarksByPid: selectBookmarksByPid(state),
        selectHighlightAnnotationById: selectHighlightAnnotationById(state),
        tags: selectTags(state),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    ownProps,
});

const connectAnnotationsBase = connect(
    mapStateToProps,
    {
        addBookmark,
        addHighlight,
        addFolder,
        addTag,
        changeActiveAnnotation,
        createFolder,
        deleteAnnotation,
        getBookmarks,
        getFolders,
        getHighlights,
        getSets,
        getTags,
        removeFolder,
        removeTag,
        reorderBookmarks,
        updateBookmarkLocation,
        updateBookmarkName,
        updateHighlightOffsets,
        updateHighlightStyle,
        updateAnnotation,
        updateAnnotationSet,
        updateNote,
    },
    mergeProps
);

export default (Component) => withRouter(connectAnnotationsBase(Component));
