import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { addFloatingPanel, getCrossRefData } from "../../actions/sidePanel";
import analytics from "../../../util/analytics";
import { createLocation, urlToDotNotation } from "../../../util/uri-utils";
import { toReducerKey } from "../../../util/reducer-helpers";
import { PanelData } from "../BasePointer";
import SmartLink from "../SmartLink";

class CrossRefPanel extends PanelData {
    constructor(args) {
        super(args);

        const { getCrossRefData, href, addFloatingPanel } = args;
        const {
            query: { lang = "eng" },
        } = createLocation(href);

        this.getCrossRefData = getCrossRefData;
        this.href = href;
        this.addFloatingPanel = addFloatingPanel;
        this.id = toReducerKey(urlToDotNotation(href), lang);
    }

    getData = () => {
        this.promisedData = this.getCrossRefData({
            uris: [{ href: this.href }],
        });

        return this.promisedData;
    };

    handle = () => {
        this.promisedData = this.promisedData || this.getData();
        this.promisedData.finally(() => this.addFloatingPanel(this));
    };
}

const CrossRefPointer = ({
    addFloatingPanel,
    children,
    className,
    disambiguate,
    getCrossRefData,
    href = "",
    trackAnalytics = true,
    ...props
}) => {
    let handleClick = (e) => {
        e.preventDefault();

        const panelData = new CrossRefPanel({
            type: "crossRef",
            getCrossRefData,
            addFloatingPanel,
            href,
        });

        trackAnalytics &&
            analytics.fireRelatedContentEvent({
                location: "main content",
                type: "link",
            });

        disambiguate(panelData);
    };

    return (
        <SmartLink
            to={href}
            className={className}
            onClick={handleClick}
            {...props}
        >
            {children}
        </SmartLink>
    );
};

const CrossRefPointerContainer = (props) => {
    const dispatch = useDispatch();
    const actions = useMemo(
        () =>
            bindActionCreators({ addFloatingPanel, getCrossRefData }, dispatch),
        [dispatch]
    );

    return <CrossRefPointer {...actions} {...props} />;
};

export default CrossRefPointerContainer;
