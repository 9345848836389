// TODO: The below disabled eslint rules were done quickly to get the code to compile. They should be reviewed and re-enabled or corrected as necessary.
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { createRef, Component, Fragment } from "react";
import styles from "./BookmarkPointer.css";
import {
    Bookmark as BookmarkIcon,
    Menu as MenuIcon,
} from "@churchofjesuschrist/eden-icons";
import { Alert as Modal } from "../../theming/components/eden-alert";
import { withRouter } from "react-router-dom";
import { PanelData } from "../BasePointer";
import connectAnnotations from "../../containers/annotations";
import { withGeneralContext } from "../GeneralContext";
import { getScrollingElement, getScrollTop } from "../../../util/scroll-utils";
import { throttle } from "../../../util/limiters";

const DragHandleIcon = (props) => (
    <MenuIcon className={styles.bookmarkModalIcon} {...props} />
);

const getAnnotatableElementFromPoint = (x, y) =>
    document
        .elementsFromPoint(x, y)
        .filter((elem) => elem.dataset.aid && !elem.dataset.aidVersion)[0];

// offset so the modal stays in position
const MODAL_X_OFFSET = 60;

class BookmarkPanel extends PanelData {
    constructor(args) {
        super(args);

        const { iconAction } = args;

        this.iconAction = iconAction;
    }

    handle = () => {
        this.iconAction(this.id);
    };
}

class BookmarkPointer extends Component {
    constructor(props) {
        super(props);
        this.input = createRef();
        this.modal = createRef();
        this.scrollingElement = getScrollingElement();

        this.state = {
            editing: false,
            activeNode: null,
            name: "",
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.active && !state.activeNode) {
            const activeNode = document.querySelector(
                `[data-aid="${props.bookmark.pid}"]`
            );
            activeNode.classList.add("bookmarkHighlight");

            return { activeNode };
        } else if (!props.active && state.activeNode) {
            return { activeNode: null };
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        const { editing } = this.state;
        const { active, simple } = this.props;

        if (!simple) {
            if (!prevProps.active && active) {
                window.addEventListener("mousedown", this.closeModal);
                window.addEventListener("touchstart", this.closeModal);
                this.modal.current
                    .querySelector(`.${styles.bookmarkModalIcon}`)
                    .addEventListener("pointerdown", this.startDragging);
            }

            if (prevProps.active && !active) {
                window.removeEventListener("mousedown", this.closeModal);
                window.removeEventListener("touchstart", this.closeModal);
            }

            if (editing) {
                this.input.current.focus();
            }
        }
    }

    closeModal = (e, force) => {
        const { editing, activeNode } = this.state;
        const { changeActiveAnnotation } = this.props;

        if ((e && !this.modal.current.contains(e.target)) || force) {
            if (editing) {
                activeNode.classList.remove("bookmarkHighlight");
                this.setState({ editing: false });
                this.updateName();
            }

            if (!editing || force) {
                this.modal.current &&
                    this.modal.current
                        .querySelector(`.${styles.bookmarkModalIcon}`)
                        .removeEventListener("pointerdown", this.startDragging);

                document
                    .querySelectorAll(".bookmarkHighlight[data-aid]")
                    .forEach((node) =>
                        node.classList.remove("bookmarkHighlight")
                    );

                changeActiveAnnotation(null);
            }
        }
    };

    handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { annotationId, disambiguate } = this.props;

        if (e.isTrusted) {
            document
                .elementsFromPoint(e.clientX, e.clientY)
                .filter((node) => node.classList.contains(styles.bookmarkIcon))
                .forEach((node) => node.click());
        } else {
            const panelData = new BookmarkPanel({
                type: "bookmark",
                id: annotationId,
                iconAction: this.iconAction,
            });

            disambiguate(panelData);
        }
    };

    iconAction = () => {
        const { active, annotationId, changeActiveAnnotation } = this.props;

        !active && changeActiveAnnotation(annotationId);
    };

    modalActions = (e, action) => {
        e.stopPropagation();
        e.preventDefault();

        const { editing } = this.state;

        if (action === "text") {
            this.setState({ editing: true });
        } else if (editing && action === "modal") {
            this.setState({ editing: false });
        }
    };

    inputChange = (e) => {
        if (e.target.value !== this.state.name) {
            this.setState({ name: e.target.value });
        }
    };

    inputKeypress = (e) => {
        // handle tab and enter keys
        if (e.keyCode === 9 || e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();

            this.setState({ editing: false });
        }
    };

    startDragging = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const dirXOffset =
            this.props.dir === "rtl" ? MODAL_X_OFFSET * -1 : MODAL_X_OFFSET;

        this.lastPointerEvent = e;
        const { activeNode } = this.state;
        this.startX = e.pageX + dirXOffset;
        this.startY = e.pageY + getScrollTop();
        this.dragging = e.currentTarget.closest("#bookmarkModal");
        this.dragging.startX = this.dragging.offsetLeft + dirXOffset;
        this.dragging.startY = this.dragging.offsetTop;
        this.scrollHeight = this.scrollingElement.scrollHeight;

        this.dragging.classList.add("dragging");
        activeNode.classList.remove("bookmarkHighlight");
        document.body.addEventListener("pointermove", this.moveDragging);
        document.addEventListener("pointerup", this.stopDragging);
        this.scrollingElement.addEventListener("scroll", this.handleScroll);
    };

    moveDragging = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const annotatableElement = getAnnotatableElementFromPoint(
            this.startX,
            e.y
        );
        this.lastPointerEvent = e;

        // Because the scrolling element is not the body (it is #flex-container), we are needing to
        // rebuild pageX and pageY to reference the current scrolling.
        const pageX = e.pageX;
        const pageY = e.pageY + this.scrollingElement.scrollTop;

        // on screens with larger pixel density movement on touch is different than movement on mouse,
        // making movementX and Y not usable, and needed this formula instead, which also works for pen input
        const movementX = pageX - this.startX;
        const movementY = pageY - this.startY;

        this.dragging.style.left = this.dragging.startX + movementX + "px";
        this.dragging.style.top = this.dragging.startY + movementY + "px";

        this.dynamicScroll(e);

        if (annotatableElement) {
            annotatableElement.classList.add("bookmarkHighlight");

            document
                .querySelectorAll(".bookmarkHighlight[data-aid]")
                .forEach((node) =>
                    node.classList.toggle(
                        "bookmarkHighlight",
                        node === annotatableElement
                    )
                );
        }
    };

    stopDragging = (e) => {
        const { coreContentUri, updateBookmarkLocation, location } = this.props;
        const annotatableElement = getAnnotatableElementFromPoint(
            this.startX,
            e.y
        );

        document.body.removeEventListener("pointermove", this.moveDragging);
        document.removeEventListener("pointerup", this.stopDragging);
        this.scrollingElement.removeEventListener("scroll", this.handleScroll);
        this.dragging.removeAttribute("style");
        this.dragging.classList.remove("dragging");

        if (annotatableElement) {
            const bookmarkLocation = {
                uri: `${coreContentUri}.${annotatableElement.id}`,
                pid: annotatableElement.dataset.aid,
            };

            updateBookmarkLocation(bookmarkLocation, location);
        }

        this.closeModal(null, true);
    };

    handleScroll = throttle(() => {
        let mockPointer = new PointerEvent("pointermove", {
            clientX: this.lastPointerEvent.clientX,
            clientY: this.lastPointerEvent.clientY,
            pageX: this.lastPointerEvent.pageX,
            pageY: this.lastPointerEvent.pageY,
        });

        document.body.dispatchEvent(mockPointer);
    }, 100);

    dynamicScroll = (e) => {
        if (e.clientY < 40 && this.scrollingElement.scrollTop) {
            if (e.clientY < 10) {
                this.scrollingElement.scrollTop -= 80;
            } else if (e.clientY < 20) {
                this.scrollingElement.scrollTop -= 40;
            } else {
                this.scrollingElement.scrollTop -= 10;
            }
        } else if (
            e.clientY > window.innerHeight - 40 &&
            this.scrollHeight >=
                this.scrollingElement.scrollTop + window.innerHeight
        ) {
            if (e.clientY > window.innerHeight - 10) {
                this.scrollingElement.scrollTop += 80;
            } else if (e.clientY > window.innerHeight - 20) {
                this.scrollingElement.scrollTop += 40;
            } else {
                this.scrollingElement.scrollTop += 10;
            }
        }
    };

    updateName = () => {
        const { name } = this.state;
        const { updateBookmarkName } = this.props;

        name && updateBookmarkName(name);
        this.setState({ name: "" });
    };

    render() {
        const { editing, name } = this.state;
        const { active, simple, bookmark } = this.props;

        return (
            <span ref={this.modal}>
                {simple ? (
                    <span
                        className={styles.bookmarkName}
                        onClick={this.iconAction}
                    >
                        <span className={styles.modalTitle}>
                            {bookmark.name}
                        </span>
                        <span>{bookmark.context}</span>
                    </span>
                ) : (
                    <Fragment>
                        <span
                            className={styles.bookmarkIcon}
                            onClick={this.handleClick}
                        >
                            <BookmarkIcon size="1.5rem" />
                        </span>
                        {active && (
                            <Modal
                                as="div"
                                className={styles.bookmarkModal}
                                onClick={(e) => this.modalActions(e, "modal")}
                                id="bookmarkModal"
                                Icon={DragHandleIcon}
                            >
                                <div className={styles.bookmarkModalBody}>
                                    {editing && (
                                        <input
                                            ref={this.input}
                                            className={styles.input}
                                            value={name}
                                            onChange={this.inputChange}
                                            onBlur={this.updateName}
                                            onKeyDown={this.inputKeypress}
                                            placeholder={bookmark.name}
                                        />
                                    )}
                                    {!editing && (
                                        <span
                                            className={styles.bookmarkName}
                                            onClick={(e) =>
                                                this.modalActions(e, "text")
                                            }
                                        >
                                            <span className={styles.modalTitle}>
                                                {bookmark.name}
                                            </span>
                                            <span>{bookmark.context}</span>
                                        </span>
                                    )}
                                </div>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </span>
        );
    }
}

const BookmarkPointerContainer = connectAnnotations(
    ({
        activeAnnotationId,
        changeActiveAnnotation,
        coreContentUri,
        ownProps,
        selectBookmarkById,
        updateBookmarkLocation,
        updateBookmarkName,
    }) => (
        <BookmarkPointer
            active={ownProps.annotationId === activeAnnotationId}
            bookmark={selectBookmarkById(ownProps.annotationId)}
            changeActiveAnnotation={changeActiveAnnotation}
            coreContentUri={coreContentUri}
            updateBookmarkLocation={updateBookmarkLocation}
            updateBookmarkName={updateBookmarkName}
            {...ownProps}
        />
    )
);

export default withRouter(withGeneralContext(BookmarkPointerContainer));
