import { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import classes from "classnames";
import analytics from "../../../util/analytics";
import SmartLink from "../SmartLink";
import Styles from "./FootnotePointer.css";
import { addFloatingPanel, getFootnoteData } from "../../actions/sidePanel";
import { PanelData } from "../BasePointer";

class FootnotePanel extends PanelData {
    constructor(args) {
        super(args);

        const { getFootnoteData, footnote = {}, addFloatingPanel } = args;

        this.getFootnoteData = getFootnoteData;
        this.addFloatingPanel = addFloatingPanel;
        this.footnote = footnote;
    }

    getData = () => {
        this.promisedData = this.getFootnoteData(this.id, {
            uris: this.footnote.referenceUris,
        });

        return this.promisedData;
    };

    handle = () => {
        this.promisedData = this.promisedData || this.getData();
        this.promisedData.finally(() => this.addFloatingPanel(this));
    };
}

const FootnotePointer = ({
    addFloatingPanel,
    children,
    className,
    disambiguate,
    footnote,
    displayFootnotes = true,
    getFootnoteData,
    isPanelContent,
    syncedScrollObserver,
    trackAnalytics = true,
    ...props
}) => {
    const self = useRef();

    useEffect(() => {
        const selfRef = self.current;
        syncedScrollObserver?.observe(selfRef);

        return () => syncedScrollObserver?.unobserve(selfRef);
    }, [syncedScrollObserver]);

    const panelData = new FootnotePanel({
        type: "footnote",
        id: footnote?.id,
        footnote,
        getFootnoteData,
        addFloatingPanel,
    });

    let handleClick = (e) => {
        e.preventDefault();
        isPanelContent &&
            analytics.fireAssociatedRelatedContentEvent({
                context: "related",
                type: "text",
            });
        trackAnalytics &&
            analytics.fireRelatedContentEvent({
                location: isPanelContent ? "related content" : "main content",
                type: "link",
            });
        disambiguate(panelData);
    };

    className = classes(className, !displayFootnotes && Styles.hidden);

    return displayFootnotes ? (
        <SmartLink
            data-scroll-id={footnote?.id}
            className={className}
            onClick={handleClick}
            ref={self}
            {...props}
        >
            {children}
        </SmartLink>
    ) : (
        <span data-scroll-id={footnote?.id} className={className} ref={self}>
            {children}
        </span>
    );
};

const FootnotePointerContainer = (props) => {
    const dispatch = useDispatch();
    const actions = useMemo(
        () =>
            bindActionCreators({ addFloatingPanel, getFootnoteData }, dispatch),
        [dispatch]
    );

    return <FootnotePointer {...actions} {...props} />;
};

export default FootnotePointerContainer;
